/* eslint-disable jsx-a11y/click-events-have-key-events */
// Hero Area with text, animations
import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { IoIosArrowDown } from "react-icons/io"
import IntroOverlay from "../components/IntroOverlay"
import gsap from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import SocialIcons from "../components/SocialIcons"
import scrollTo from "gatsby-plugin-smoothscroll"
import { FaFileDownload } from "react-icons/fa"
import SakshamPdf from "../../static/saksham-kapoor-resume.pdf"

const homeAnimation = () => {
  let tl = gsap.timeline()
  tl.to(".banner__quote .line", 0.01, {
    color: "#fff",
  })
    .to(".nav-controls__brand", 0.01, {
      color: "#fff",
    })
    .to(".nav-controls__hamburger", 0.01, {
      color: "#fff",
    })
    .from(".banner__quote .line", 1.8, {
      y: 40,
      opacity: 0,
      ease: "power4.out",
      delay: 1,
      stagger: 0.3,
    })
    .to(".overlay-top", 0.7, {
      delay: -0.35,
      height: 0,
      ease: "expo.out",
      stagger: 0.3,
    })
    .to(".overlay-top-1", 0.9, {
      height: 0,
      ease: "power4.out",
    })
    .from(".nav-controls", 0.4, {
      delay: -0.3,
      opacity: 0,
    })
    .from(".banner__intro .line", 0.7, {
      delay: -0.2,
      opacity: 0,
      x: -20,
      ease: "bounce.back",
    })
    .from(".banner__developer .line", 0.7, {
      delay: -0.3,
      opacity: 0,
      x: 20,
      ease: "bounce.back",
    })
    .from(".banner__icons .banner__icon", 0.5, {
      opacity: 0,
      skewX: 1,
      y: -20,
      ease: "power4.back",
      stagger: {
        amount: 0.4,
      },
    })
    .to(".intro-overlay .top", 0.01, {
      css: { display: "none", pointerEvents: "none" },
    })
    .to(".intro-overlay", 0.01, {
      css: { display: "none", pointerEvents: "none" },
    })
    .to("body", 0.01, {
      css: { overflow: "auto" },
    })
}

const homeAnimationMobile = () => {
  let tl = gsap.timeline()
  tl.to(".banner__quote .line", 0.01, {
    color: "#fff",
  })
    .to(".nav-controls__brand", 0.01, {
      color: "#fff",
    })
    .to(".nav-controls__hamburger", 0.01, {
      color: "#fff",
    })
    .from(".banner__quote .line", 1.8, {
      y: 40,
      opacity: 0,
      ease: "power4.out",
      delay: 1,
      stagger: 0.3,
    })
    .to(".overlay-top", 0.7, {
      delay: -0.35,
      height: 0,
      ease: "expo.out",
    })
    .to(".overlay-top-1", 0.9, {
      height: 0,
      ease: "power4.out",
    })
    .from(".nav-controls", 0.4, {
      delay: -0.3,
      opacity: 0,
    })
    .from(".banner__intro .line", 0.7, {
      delay: -0.2,
      opacity: 0,
      x: -15,
      ease: "bounce.back",
    })
    .from(".banner__developer .line", 0.7, {
      delay: -0.3,
      opacity: 0,
      x: 15,
      ease: "bounce.back",
    })
    .from(".banner__icons .banner__icon", 0.5, {
      opacity: 0,
      skewX: 1,
      y: -20,
      ease: "power4.back",
      stagger: {
        amount: 0.4,
      },
    })
    .to(".intro-overlay .top", 0.01, {
      css: { display: "none", pointerEvents: "none" },
    })
    .to(".intro-overlay", 0.01, {
      css: { display: "none", pointerEvents: "none" },
    })
    .to("body", 0.01, {
      css: { overflow: "auto" },
    })
}

const Banner = () => {
  useEffect(() => {
    gsap.registerPlugin(CSSPlugin)
    //on load time
    let width = window.innerWidth
    if (width >= 1024) {
      homeAnimation()
    } else {
      homeAnimationMobile()
    }
  }, [])

  const data = useStaticQuery(
    graphql`
      query {
        banner_img: file(relativePath: { eq: "home_banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.banner_img.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      className="banner"
      id="navTop"
      fluid={imageData}
    >
      <IntroOverlay />
      <div className="banner__content">
        <div className="banner__content__text">
          <div className="banner__intro">
            <div className="line">
              Hi, I'm <span> Saksham Kapoor</span>
            </div>
          </div>
          <div className="banner__quote">
            <h1>
              <div className="line">
                I Strive <span>To Make The Web</span>
              </div>
              <div className="line">
                <span> A Beautiful Place</span>
              </div>
            </h1>
          </div>
          <div className="banner__developer">
            <div className="line">
              Full stack <span> web developer</span>
            </div>
          </div>
        </div>
        <div className="banner__content__social">
          <div className="banner__icons">
            <SocialIcons />
            <a
              href={SakshamPdf}
              target="_blank"
              rel="noreferrer"
              className="banner__icon"
              aria-label="download resume"
            >
              <FaFileDownload />
            </a>
          </div>
        </div>
      </div>
      <div
        className="banner__scrollIndicator noTap"
        role="button"
        tabIndex="0"
        onClick={() => scrollTo("#navMyWork")}
      >
        <div className="text">My Work</div>
        <IoIosArrowDown className="scroll-down-icon" />
        <IoIosArrowDown className="scroll-down-icon" />
      </div>
    </BackgroundImage>
  )
}

export default Banner
