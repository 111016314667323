// Will import Navbar and Footer
// And every page will import layout
// Seo goes in this page
import React, { useState, useEffect } from "react"
import Navigation from "./Navigation"
import NavControls from "./NavControls"
import Footer from "./Footer"
import NavContextProvider from "../contexts/NavContext"
import BackToTop from "./BackToTop"

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const Layout = ({ children }) => {
  const [dimensionChangeCount, setDimensionChangeCount] = useState(0)

  useEffect(() => {
    const backToTopButton = document.querySelector("#back-to-top-btn")
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 600) {
        if (!backToTopButton.classList.contains("btnEntrance")) {
          backToTopButton.classList.remove("btnExit")
          backToTopButton.classList.add("btnEntrance")
          backToTopButton.style.display = "block"
        }
      } else {
        if (backToTopButton.classList.contains("btnEntrance")) {
          backToTopButton.classList.remove("btnEntrance")
          backToTopButton.classList.add("btnExit")
          setTimeout(function () {
            backToTopButton.style.display = "none"
          }, 250)
        }
      }
    })

    const debouncedHandleResize = debounce(function HandleResize() {
      setDimensionChangeCount((prevState, props) => {
        return prevState + 1
      })
    }, 1000)

    window.addEventListener("resize", debouncedHandleResize)
  }, [])

  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [dimensionChangeCount])

  return (
    <>
      <NavContextProvider>
        <Navigation />
        <NavControls />
      </NavContextProvider>
      <div className="appWrapper">{children}</div>
      <Footer />
      <BackToTop />
    </>
  )
}

export default Layout
