import React from "react"

const TestimonialThumbnail = ({ testimonial }) => {
  const { review, name, org, designation } = testimonial
  return (
    <div className="testimonials__thumbnail">
      <div className="testimonials__thumbnail__content">
        <div className="testimonials__thumbnail__text">{review}</div>
        <div className="testimonials__thumbnail__author">
          <div className="author__name">{name}</div>
          <div className="author__company">
            {designation}, {org}
          </div>
          {/* <div className="author__designation">{designation}</div> */}
        </div>
      </div>
    </div>
  )
}

export default TestimonialThumbnail
