import React, { createContext, useState } from "react"

export const NavContext = createContext()

const NavContextProvider = props => {
  const [navOpen, setNavOpen] = useState(false)
  const navToggle = () => {
    setNavOpen((prevState, props) => {
      return !prevState
    })
  }
  return (
    <NavContext.Provider value={{ navOpen, navToggle: navToggle }}>
      {props.children}
    </NavContext.Provider>
  )
}

export default NavContextProvider
