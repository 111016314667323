import React, { useState, useEffect, useRef } from "react"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const nameRef = useRef()
  const emailRef = useRef()
  const messageRef = useRef()

  useEffect(() => {
    if (name !== "") {
      nameRef.current.classList.add("label-styled")
    } else {
      if (nameRef.current.classList.contains("label-styled")) {
        nameRef.current.classList.remove("label-styled")
      }
    }
  }, [name])

  useEffect(() => {
    if (email !== "") {
      emailRef.current.classList.add("label-styled")
    } else {
      if (emailRef.current.classList.contains("label-styled")) {
        emailRef.current.classList.remove("label-styled")
      }
    }
  }, [email])

  useEffect(() => {
    if (message !== "") {
      messageRef.current.classList.add("label-styled")
    } else {
      if (messageRef.current.classList.contains("label-styled")) {
        messageRef.current.classList.remove("label-styled")
      }
    }
  }, [message])

  return (
    <form
      className="contactform"
      name="contact"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      action="/success"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className="input-group animate_input">
        <input
          value={name}
          onChange={e => {
            setName(e.target.value)
          }}
          name="name"
          type="text"
          required
        />
        <label ref={nameRef} htmlFor="name">
          Name
        </label>
      </div>
      <div className="input-group animate_input">
        <input
          value={email}
          onChange={e => {
            setEmail(e.target.value)
          }}
          name="email"
          type="email"
          required
        />
        <label ref={emailRef} htmlFor="email">
          Email
        </label>
      </div>

      <div className="input-group animate_input">
        <textarea
          value={message}
          onChange={e => {
            setMessage(e.target.value)
          }}
          name="message"
          id="message"
          rows="3"
          required
        ></textarea>
        <label ref={messageRef} htmlFor="message">
          Message
        </label>
      </div>
      <div className="input-group animate_input">
        <div className="contact-s__calltoaction noTap">
          <button type="submit">Send Message</button>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
