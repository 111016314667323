import React from "react"

const SkillSet = ({ images, trim }) => {
  const getName = image => {
    if (trim) {
      const name = image.node.name.substring(1)
      return name
    } else {
      return image.node.name
    }
  }

  return (
    <div className="skills__grid">
      {images.map(image => (
        <div className="skills__skill" key={image.node.name}>
          <img src={image.node.publicURL} alt={getName(image)} />
          <p>{getName(image)}</p>
        </div>
      ))}
    </div>
  )
}

export default SkillSet
