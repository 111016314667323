import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import SkillSet from "./SkillSet"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const SkillsSection = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    // All 'Skills' Animation

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".skills",
        start: "top 10%",
      },
    })

    tl.from(".skills__underlay .animate_line", {
      delay: 0.5,
      duration: 0.8,
      opacity: 0,
      stagger: {
        amount: 0.7,
      },
    })

    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".skills",
        start: "top 70%",
      },
    })

    tl2.from(".mywork__headings .animate_line2", {
      duration: 0.5,
      y: 50,
      opacity: 0,
      stagger: {
        amount: 0.3,
      },
      ease: "expo.inOut",
    })

    const my_skills_array = ["01", "02", "03"]

    my_skills_array.forEach(num => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: `.animate_skillbox_${num}`,
            start: `top 72%`,
          },
        })
        .from(`.animate_skillbox_${num} h3`, {
          duration: 0.6,
          opacity: 0,
          x: -40,
          ease: "expo.out",
        })
        .from(`.animate_skillbox_${num} img`, {
          delay: -0.08,
          duration: 0.6,
          opacity: 0,
          y: 10,
          ease: "expo.out",
          stagger: {
            amount: 0.4,
          },
        })
        .from(`.animate_skillbox_${num} p`, {
          delay: -0.05,
          duration: 0.5,
          opacity: 0,
          y: 10,
          ease: "expo.out",
          stagger: {
            amount: 0.4,
          },
        })
    })
  }, [])
  const data = useStaticQuery(
    graphql`
      query {
        languages: allFile(
          filter: { relativeDirectory: { eq: "skill_icons/languages" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        frameworks: allFile(
          filter: { relativeDirectory: { eq: "skill_icons/frameworks" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
        design: allFile(
          filter: { relativeDirectory: { eq: "skill_icons/design" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `
  )
  const languageImages = data.languages.edges
  const frameworkImages = data.frameworks.edges
  const designImages = data.design.edges

  return (
    <section className="skills">
      <div className="skills__underlay">
        <h1>
          <div className="line animate_line">
            <span>MY</span>
          </div>
          <div className="line animate_line">
            <span>SKILLS</span>
          </div>
        </h1>
      </div>
      <div className="mywork__headings z-index-1">
        <div className="mywork__headings--main">
          <h2>
            <div className="line animate_line2">My Skills</div>
          </h2>
        </div>
        <div className="mywork__headings--sub">
          <h2>
            <div className="line animate_line2">
              I love to build amazing experiences and enjoy bringing ideas to
              life.
            </div>
          </h2>
        </div>
      </div>
      <div className="skills__content">
        <div className="skills__skillset animate_skillbox_01">
          <h3>Languages I Speak</h3>
          <SkillSet images={languageImages} trim={true} num="01" />
        </div>
        <div className="skills__skillset animate_skillbox_02">
          <h3>Frameworks/Libraries I Use</h3>
          <SkillSet images={frameworkImages} trim={true} num="02" />
        </div>
        <div className="skills__skillset animate_skillbox_03">
          <h3>Design Tools I Use</h3>
          <SkillSet images={designImages} trim={false} num="03" />
        </div>
      </div>
    </section>
  )
}

export default SkillsSection
