import React, { useEffect } from "react"
import ContactForm from "./ContactForm"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const ContactSection = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    // All 'Contact' Animation

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".contact-s",
        start: "top 55%",
      },
    })

    tl.from(".contact-s .animate_contact_line", {
      duration: 0.5,
      y: 50,
      opacity: 0,
      stagger: {
        amount: 0.3,
      },
      ease: "expo.inOut",
    })
      .from(".contact-s .animate_input", {
        duration: 0.9,
        opacity: 0,
        stagger: {
          amount: 0.7,
        },
      })
      .from(".contact-s__underlay .line", {
        duration: 0.8,
        opacity: 0,
        stagger: {
          amount: 0.7,
        },
      })
  }, [])

  return (
    <div className="contact-s" id="navContact">
      <div className="contact-s__underlay">
        <h1>
          <div className="line">
            <span>SAY</span>
          </div>
          <div className="line">
            <span>HELLO!</span>
          </div>
        </h1>
      </div>
      <div className="mywork__headings z-index-1">
        <div className="mywork__headings--main">
          <h2>
            <div className="line animate_contact_line">Get In Touch</div>
          </h2>
        </div>
        <div className="mywork__headings--sub">
          <h2>
            <div className="line animate_contact_line">
              Let’s make something together, start by saying hi!
            </div>
          </h2>
        </div>
      </div>
      <div className="contact-s__content">
        <ContactForm />
      </div>
    </div>
  )
}

export default ContactSection
