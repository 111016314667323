import React, { useEffect } from "react"
import TestimonialSlider from "./TestimonialSlider"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const TestimonialSection = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    // All 'Testimonials' Animation

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".testimonials",
        start: "top 51%",
      },
    })

    tl.from(".testimonials__underlay .line", {
      duration: 0.3,
      opacity: 0,
      ease: "expo.inOut",
    })
      .from(".testimonials__slider .line", {
        duration: 0.8,
        opacity: 0,
        y: 20,
        ease: "expo.inOut",
      })
      .from(".testimonials__slider .slider_container", {
        duration: 0.6,
        opacity: 0,
      })
      .from(".testimonials__content .hover-effect", {
        duration: 0.6,
        opacity: 0,
      })
  }, [])

  const data = useStaticQuery(graphql`
    query {
      testimonial: file(relativePath: { eq: "testimonials.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="testimonials" id="navTestimonials">
      <div className="testimonials__underlay">
        <h1>
          <div className="line">
            <span>TESTIMONIALS</span>
          </div>
        </h1>
      </div>
      <div className="testimonials__content">
        <div className="testimonials__slider">
          <h1>
            <div className="line">Testimonials</div>
          </h1>
          <TestimonialSlider />
        </div>
        <div className="hover-effect">
          <Img
            fluid={data.testimonial.childImageSharp.fluid}
            alt="saksham kapoor testimonials"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </section>
  )
}

export default TestimonialSection
