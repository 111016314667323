import React from "react"
import { FaGithub, FaLinkedinIn, FaCodepen, FaEnvelope } from "react-icons/fa"

const SocialIcons = () => {
  return (
    <>
      <a
        href="https://github.com/saksham-kapoor"
        className="banner__icon"
        target="_blank"
        rel="noreferrer"
        aria-label="open github"
      >
        <FaGithub />
      </a>
      <a
        href="https://www.linkedin.com/in/saksham-kapoor/"
        className="banner__icon"
        target="_blank"
        rel="noreferrer"
        aria-label="open linkedin"
      >
        <FaLinkedinIn />
      </a>
      <a
        href="https://codepen.io/saksham-kapoor"
        className="banner__icon"
        target="_blank"
        rel="noreferrer"
        aria-label="open codepen"
      >
        <FaCodepen />
      </a>
      <a
        href="mailto:hello@sakshamkapoor.me"
        aria-label="send me an email"
        className="banner__icon"
      >
        <FaEnvelope />
      </a>
    </>
  )
}

export default SocialIcons
