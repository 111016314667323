import React from "react"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import MyWork from "../components/MyWork"
import AboutMe from "../components/AboutMe"
// import BlogSection from "../components/BlogSection"
import TestimonialSection from "../components/TestimonialSection"
import ContactSection from "../components/ContactSection"
import SkillsSection from "../components/SkillsSection"
import Seo from "../components/Seo"

const index = () => {
  return (
    <Layout>
      <Seo />
      <Banner />
      <MyWork />
      <AboutMe />
      {/* <BlogSection /> */}
      <SkillsSection />
      <TestimonialSection />
      <ContactSection />
    </Layout>
  )
}

export default index
