/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import BackgroundImage from "gatsby-background-image"

const WorkThumbnail = props => {
  const { number, heading, description, img, btnText, btnLink } = props

  return (
    <BackgroundImage
      Tag="section"
      className={`mywork__thumbnail animate_workbox_${number}`}
      fluid={img}
      backgroundColor={`#040e18`}
    >
      <div className={`mywork__thumbnail__number animate_number_${number}`}>
        {number}
      </div>
      <div className="mywork__thumbnail__content">
        <div
          className={`mywork__thumbnail__content__heading animate_heading_${number}`}
        >
          {heading}
        </div>
        <div
          className={`mywork__thumbnail__content__description animate_description_${number}`}
        >
          {description}
        </div>
        <div className="mywork__thumbnail__content__buttons">
          {btnLink === "#" && (
            <a
              className={`mywork__thumbnail__content__button animate_button_${number}`}
              style={{
                backgroundColor: "rgba(255,255,255,0.8)",
                pointerEvents: "none",
              }}
            >
              {btnText}
            </a>
          )}
          {btnLink !== "#" && (
            <a
              href={btnLink}
              target="_blank"
              rel="noreferrer"
              className={`mywork__thumbnail__content__button animate_button_${number}`}
            >
              {btnText}
            </a>
          )}
        </div>
      </div>
    </BackgroundImage>
  )
}

export default WorkThumbnail
