import React, { useEffect } from "react"
import WorkThumbnail from "./WorkThumbnail"
import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const MyWork = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    // All 'My Work' Animation

    let tl_headings = gsap.timeline({
      scrollTrigger: {
        trigger: ".mywork",
        start: "top 70%",
      },
    })

    tl_headings.from(".mywork__headings .animate_line", {
      duration: 0.5,
      y: 50,
      opacity: 0,
      stagger: {
        amount: 0.3,
      },
      ease: "expo.inOut",
    })

    const my_work_array = ["01", "02", "03", "04"]

    my_work_array.forEach(num => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: `.animate_workbox_${num}`,
            start: `top 70%`,
          },
        })
        .from(`.animate_workbox_${num}`, {
          duration: 0.6,
          opacity: 0,
        })
        .from(`.animate_number_${num}`, {
          duration: 0.3,
          opacity: 0,
          x: -40,
          ease: "expo.out",
        })
        .from(`.animate_heading_${num}`, {
          delay: -0.08,
          duration: 0.35,
          opacity: 0,
          y: 10,
          ease: "expo.out",
        })
        .from(`.animate_description_${num}`, {
          delay: -0.05,
          duration: 0.25,
          opacity: 0,
          y: 10,
          ease: "expo.out",
        })
        .from(`.animate_button_${num}`, {
          duration: 0.25,
          opacity: 0,
          x: 10,
          ease: "expo.out",
        })
    })
  }, [])

  const data = useStaticQuery(
    graphql`
      query {
        work_dhriti: file(relativePath: { eq: "work_dhriti.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        work_houseitt: file(relativePath: { eq: "work_houseitt.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        work_aishwarya: file(relativePath: { eq: "aishwarya_soni.webp" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        work_poetry: file(relativePath: { eq: "work_poetry.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const projects = [
    {
      number: "01",
      heading: "Houseitt",
      description: "Real Estate Startup",
      img: data.work_houseitt.childImageSharp.fluid,
      btnText: "VISIT SITE",
      btnLink: "https://houseitt.in",
    },
    {
      number: "02",
      heading: "Aishwarya Soni",
      description: "Content Creator",
      img: data.work_aishwarya.childImageSharp.fluid,
      btnText: "VISIT SITE",
      btnLink: "https://aish-thetics.com",
    },
    {
      number: "03",
      heading: "Dhriti Nritya Academy",
      description: "Classical Dance Academy",
      img: data.work_dhriti.childImageSharp.fluid,
      btnText: "VISIT SITE",
      btnLink: "https://dhritinritya.com",
    },
    {
      number: "04",
      heading: "Poetry Darbaar",
      description: "Non-Profit Poetry Organisation",
      img: data.work_poetry.childImageSharp.fluid,
      btnText: "VISIT SITE",
      btnLink: "https://poetrydarbaar.com",
    }
  ]

  return (
    <section className="mywork" id="navMyWork">
      <div className="mywork__headings">
        <div className="mywork__headings--main">
          <h2>
            <div className="line animate_line">Latest Projects</div>
          </h2>
        </div>
        <div className="mywork__headings--sub">
          <h2>
            <div className="line animate_line">
              Here are a few projects I've worked on recently. Want to see more?
              <a
                href="mailto:hello@sakshamkapoor.me"
                className="rm-a-styles noTap"
              >
                Email me.
              </a>
            </div>
          </h2>
        </div>
      </div>
      <div className="mywork__container">
        {projects.map(project => (
          <WorkThumbnail
            key={project.number}
            number={project.number}
            heading={project.heading}
            description={project.description}
            img={project.img}
            btnText={project.btnText}
            btnLink={project.btnLink}
          />
        ))}
      </div>
    </section>
  )
}

export default MyWork
