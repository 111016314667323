import React, { useEffect } from "react"
import SocialIcons from "./SocialIcons"
import { Player } from "@lottiefiles/react-lottie-player"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const AboutMe = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    // All 'About' Animation

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".aboutme",
        start: "top 51%",
      },
    })

    tl.from(".aboutme__underlay .animate_line", {
      duration: 0.3,
      opacity: 0,
      stagger: {
        amount: 0.3,
      },
      ease: "expo.inOut",
    })
      .from(".aboutme__description .line", {
        duration: 0.8,
        opacity: 0,
        y: 20,
        ease: "expo.inOut",
      })
      .from(".aboutme__description .aboutme__text", {
        duration: 0.6,
        opacity: 0,
      })
      .from(".aboutme__social a", {
        duration: 0.7,
        opacity: 0,
        stagger: {
          amount: 0.3,
        },
      })
      .from(".aboutme__content .hover-effect", {
        duration: 0.6,
        opacity: 0,
      })
  }, [])
  return (
    <section className="aboutme" id="navAboutMe">
      <div className="aboutme__underlay">
        <h1>
          <div className="line animate_line">
            <span>ABOUT</span>
          </div>
          <div className="line animate_line">
            <span>ME</span>
          </div>
        </h1>
      </div>
      <div className="aboutme__content">
        <div className="aboutme__description">
          <h1>
            <div className="line">About Me</div>
          </h1>
          <div className="aboutme__text">
            I'm a <span>Full Stack Web Developer</span> and
            <span> UI/UX Designer</span> based in Delhi, India. I'm passionate
            about web development and love to code beautiful and attractive
            things. I'm always ready to challenge myself and explore new areas
            of work. I never stop learning and for me, each new project is
            another adventure.
            <br />
            <br />
            Currently working as a frontend developer at Houseitt.
            <br />
            <br />
            Connect With Me
            <div className="aboutme__social">
              <SocialIcons />
            </div>
          </div>
        </div>
        <div className="hover-effect">
          <Player
            autoplay={true}
            controls={false}
            loop={true}
            src="https://assets7.lottiefiles.com/private_files/lf30_WdTEui.json"
            style={{ width: "100%" }}
            mode="bounce"
            background="transparent"
            speed="2"
          ></Player>
        </div>
      </div>
    </section>
  )
}

export default AboutMe
