import React from "react"
import TestimonialThumbnail from "./TestimonialThumbnail"
import Slider from "react-slick"

const TestimonialSlider = () => {
  const testimonials = [
    {
      review: `Saksham came across as a young dynamic perfectionist. He was able to comprehend and transcend the inputs delivered to him into a spectacular website of my Dance Academy. His efforts are worth being praised. We wish him all the best for his future endeavours.`,
      name: "Smt. Smiti Gupta Negi",
      org: "Dhriti Nritya Academy",
      designation: "Director",
    },
    {
      review: `I started my housing startup one year ago and as many startup founders struggle through tech part, I was also in the same boat.
      Then I met Saksham through a mutual contact. From then and till now he has been helping us out with all the front end requirements. Before taking up work he not only understands the business but also remains fully dedicated so that a best possible product can be made.`,
      name: "Bharat Bhatt",
      org: "Houseitt",
      designation: "Founder",
    },
    {
      review: `Saksham designed my website a year ago and I am extremely pleased with everything he did -  from the aesthetic layout to customized designing. He understood the concept and provided his creative inputs to make it even better.
        I found him reliable and his charges were extremely reasonable. As a matter of fact, I think I got more than what I paid for.`,
      name: "Indrajit Ghoshal",
      org: "Poetry Darbaar",
      designation: "Founder",
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4500,
    fadeIn: false,
    autoplay: true,
    arrows: false,
  }

  return (
    <div className="slider_container">
      <Slider {...settings}>
        {testimonials.map((testimonial, i) => (
          <TestimonialThumbnail key={i} testimonial={testimonial} />
        ))}
      </Slider>
    </div>
  )
}

export default TestimonialSlider
