import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { IoMdRocket } from "react-icons/io"

const BackToTop = () => {
  return (
    <button
      id="back-to-top-btn"
      className="noTap"
      onClick={() => scrollTo("#navTop")}
      aria-label="back to top"
    >
      <IoMdRocket />
    </button>
  )
}

export default BackToTop
