/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useRef, useEffect } from "react"
import gsap from "gsap"
import { NavContext } from "../contexts/NavContext"
import { FiX } from "react-icons/fi"
import { FaGripLines } from "react-icons/fa"
const NavControls = () => {
  const { navOpen, navToggle } = useContext(NavContext)
  const [isNavAnimation, setIsNavAnimation] = useState(false)
  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    } else {
      let width = window.innerWidth
      let tl = gsap.timeline({
        onStart: () => {
          setIsNavAnimation(true)
        },
        onComplete: () => {
          setIsNavAnimation(false)
        },
      })
      if (navOpen) {
        // Desktop Opening
        if (width >= 1024) {
          tl.to("body", 0.01, { css: { overflow: "hidden" } })
            .to(".navigation", 0.5, {
              x: "100vw",
              opacity: 1,
            })
            .from(
              ".navigation__info .navigation__info__name .line span",
              0.85,
              {
                delay: -0.4,
                opacity: 0,
                ease: "power4.inOut",
                stagger: {
                  amount: 0.85,
                },
              }
            )
            .from(".navigation__link", 1.15, {
              delay: -0.4,
              x: 80,
              opacity: 0,
              ease: "power4.inOut",
              stagger: {
                amount: 0.85,
              },
            })
        } else {
          // Mobile Opening
          tl.to("body", 0.01, { css: { overflow: "hidden" } })
            .to(".navigation", 0.5, {
              x: "100vw",
              opacity: 1,
            })
            .from(".navigation__link", 0.9, {
              delay: -0.4,
              x: 35,
              opacity: 0,
              ease: "power4.inOut",
              stagger: {
                amount: 0.65,
              },
            })
            .from(".navigation__info__email", 0.45, {
              x: 20,
              opacity: 0,
              ease: "power4.inOut",
            })
            .from(".navigation__social", 0.5, {
              opacity: 0,
              delay: -0.95,
              x: 10,
              stagger: {
                amount: 0.25,
              },
              ease: "power4.inOut",
            })
        }
      } else {
        // Desktop & Mobile Closing

        tl.to(".navigation", 0.35, {
          opacity: 0,
        })
          .to(".navigation", 0.6, {
            delay: -0.35,
            x: 0,
            ease: "power4.inOut",
          })
          .to("body", 0.01, {
            css: { overflow: "auto" },
          })
      }
    }
  }, [navOpen])
  const getStyle = () => {
    if (isNavAnimation) {
      return { visibility: "hidden" }
    } else {
      return { visibility: "visible" }
    }
  }
  return (
    <>
      <div className="nav-controls">
        <div className="nav-controls__brand noTap">&lt; Saksham /&gt;</div>

        <div
          className="nav-controls__hamburger noTap"
          style={getStyle()}
          role="button"
          tabIndex="0"
          onClick={navToggle}
          aria-label="toggle navigation menu"
        >
          {navOpen && <FiX />}
          {!navOpen && <FaGripLines />}
        </div>
      </div>
    </>
  )
}

export default NavControls
