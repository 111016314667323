/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { NavContext } from "../contexts/NavContext"
import SakshamPdf from "../../static/saksham-kapoor-resume.pdf"

const Navigation = () => {
  const { navToggle } = useContext(NavContext)
  const navigate = target => {
    navToggle()
    setTimeout(() => {
      scrollTo(target)
    }, 500)
  }
  return (
    <section className="navigation">
      <div className="navigation__content">
        <div className="navigation__links">
          <div
            className="navigation__link noTap"
            role="button"
            tabIndex="0"
            onClick={() => navigate("#navMyWork")}
          >
            My Work
          </div>
          <div
            className="navigation__link noTap"
            role="button"
            tabIndex="0"
            onClick={() => navigate("#navAboutMe")}
          >
            About Me
          </div>
          <div
            className="navigation__link noTap"
            role="button"
            tabIndex="0"
            onClick={() => navigate("#navTestimonials")}
          >
            Testimonials
          </div>
          {/* <div className="navigation__link">Blog</div> */}
          <div
            className="navigation__link noTap"
            role="button"
            tabIndex="0"
            onClick={() => navigate("#navContact")}
          >
            Contact
          </div>
          <a
            href={SakshamPdf}
            role="button"
            tabIndex="0"
            target="_blank"
            rel="noreferrer"
            className="navigation__link noTap navigation__link--hide-large rm-a-default"
          >
            Resume
          </a>
        </div>

        <div className="navigation__info">
          <div className="navigation__info__name">
            <h1>
              <div className="line">
                <span>I Build</span>
              </div>
              <div className="line">
                <span>Amazing</span>
              </div>
              <div className="line">
                <span>Experiences</span>
              </div>
            </h1>
          </div>
          <div className="navigation__info__social">
            <div className="navigation__social noTap">
              <a
                href="https://www.github.com/saksham-kapoor"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
            </div>
            <div className="navigation__social noTap">
              <a
                href="https://www.linkedin.com/in/saksham-kapoor/"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
            </div>
          </div>
          <a
            href="mailto:hello@sakshamkapoor.me"
            className="navigation__info__email"
          >
            Shoot me an email!
          </a>
        </div>
      </div>
    </section>
  )
}

export default Navigation
