import React from "react"
import SocialIcons from "./SocialIcons"

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer__content">
        <div className="footer__links">
          <SocialIcons />
        </div>
        <div className="footer__copyright">
          &copy; 2020 Saksham Kapoor{" "}
          <a
            href="https://sakshamkapoor.me/sitemap.xml"
            className="rm-a-styles noTap"
          >
            Sitemap
          </a>
        </div>
      </div>
    </section>
  )
}

export default Footer
